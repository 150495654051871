<template>
  <v-row align="center" justify="center" style="height: 90vh" no-gutters>
    <v-col cols="12" style="position: relative">
      <loader />
    </v-col>
  </v-row>
</template>

<script>
import Loader from "@/components/Loader/pageLoader.vue";

export default {
  name: "SignInView",
  components: {
    Loader,
  },
  computed: {
    query() {
      // -- Parametros --
      // token: (* obligatorio) token de acceso
      // page: Pagina a redireccionar luego de acceder
      // decoded: Token ya descodificado
      // --- Parametros de pagina ---
      // document_number: numero de documento del cliente
      // document_type: tipo de documento del cliente
      // quot_number: numero de cotizacion
      // proposal_number: numero de propuesta
      // step: numero del paso a retomar
      if (process.env.VUE_APP_AMBIENTE === "MARSH") {
        sessionStorage.setItem("jwt-variable", this.$route.query.jwtvariable);
      }
      return this.$route.query;
    },
  },
  async mounted() {
    this.signIn(this.encryptToken());
  },
  methods: {
    signIn(token = "") {
      console.log("signIn", token);
      
      this.$store.dispatch("signIn", token).then(() => {
        let route = {};
        const {
          page,
          document_number,
          document_type,
          quot_number,
          proposal_number,
          proposal_step,
        } = this.query;
        console.log({page, document_number, document_type, quot_number, proposal_number, proposal_step});
        
        switch (page) {
          case "1":
          case 1: {
            route.name = "UserDetail";
            route.params = {
              document_type,
              document_number,
            };
            break;
          }

          case "2":
          case 2: {
            route.name = "NewQuotation";
            route.params = {
              document_type,
              document_number,
            };
            break;
          }

          case "3":
          case 3: {
            route.name = "quotationOffers";
            route.params = { id: quot_number };
            break;
          }

          case "4":
          case 4: {
            route.name = "ShoppingCart";
            route.params = { propuesta: proposal_number };
            break;
          }

          case "5":
          case 5: {
            route.name = "Contract";
            route.params = { id: proposal_number };

            const step = proposal_step ?? 1;
            route.query = { step };
            break;
          }

          default:
            route.name = "dashboard";
            break;
        }
        this.$router.push(route);
      });
    },
    encryptToken() {
      const { token, decoded } = this.query;
      console.log({ token, decoded });
      
      console.log("VUE_APP_AMBIENTE", process.env.VUE_APP_AMBIENTE);
      console.log("VUE_APP_BASE_URL", process.env.VUE_APP_BASE_URL);
      console.log("VUE_APP_API_TOKEN", process.env.VUE_APP_API_TOKEN);
      console.log("VUE_APP_X_MESOS_KEY", process.env.VUE_APP_X_MESOS_KEY);


      let encryptToken;
      if (!decoded || decoded == "0" || decoded === "false") {
        console.log("VUE_APP_CRYPTO_KEY", process.env.VUE_APP_CRYPTO_KEY);
       try {
        encryptToken = this.$CryptoJS.AES.decrypt(
          token,
          process.env.VUE_APP_CRYPTO_KEY
        ).toString(this.$CryptoJS.enc.Utf8);
        console.log("encryptToken", encryptToken);
       } catch (error) {
        console.error("Error al desencriptar token", error);
        console.error("Error al desencriptar token 2", error.message);
       }
        
      } else {
        encryptToken = token;
        console.log("encryptToken default", encryptToken);

      }
      console.log("encryptToken return", encryptToken);
      return encryptToken;
    },
  },
};
</script>
